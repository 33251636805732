const HorizontalSidebarData = [
  {
    title: 'Dashboard',
    href: '/Dashboards/AdminDashboard',
    id: 1,
    suffixColor: 'bg-info text-dark-white',
    icon: <i className="bi bi-house"></i>,
  },
  {
    title: 'User Management',
    href: '#',
    id: 2,
    suffixColor: 'bg-info text-dark-white',
    icon: <i className="bi bi-person"></i>,
    collapisble: true,
    children: [
      // {
      //   title: 'ROLE',
      //   href: '/Role/AllRole',
      //   id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Business Partner',
      //   href: '/UseManagement/BusinessPartner',
      //   id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Master Distributor',
      //   href: '/UseManagement/MasterDistributor',
      //   id: 10,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      {
        title: 'Super Distributor',
        href: '/UserManagement/SuperDistributor',
        id: 6,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'Distributor Master',
        href: '/Distributor/Distributor',
        id: 10,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'Retailer Master',
        href: '/Retailer/Retailer',
        id: 14,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'User Service Status',
        href: '/Admin/UserServiceStatus',
        id: 14,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'User KYC Pending',
        href: '/Admin/PendingKycVerification',
        id: 14,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      // {
      //   title: 'Employee List',
      //   href: '/UseManagement/Employee',
      //   id: 14,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'User Request',
      //   href: '/Admin/UserRequest',
      //   id: 14,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Set User KYC Amount',
      //   href: '/Admin/SetUserKycAmount',
      //   id: 14,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
    ],
  },

  {
    title:'Balance Request',
    href: '#',
    id: 2,
    suffixColor: 'bg-info text-dark-white',
    icon: <i className="bi bi-bag-check"></i>,
    collapisble: true,
    children: [
      {
        title: 'Balance Request',
        href: '/Admin/ViewBalanceRequestAdmin',
        id: 6,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
    ],
  },
  {
    title: 'Commission',
    href: '#',
    id: 2,
    suffixColor: 'bg-info text-dark-white',
    icon: <i className="bi bi-bag-check"></i>,
    collapisble: true,
    children: [
     
      {
        title: 'Plan',
        href: '/Admin/AllPlans',
        id: 10,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },

      {
        title:'Commission Plan Master',
        href: '/Admin/GetCommissionPlanMaster',
        id: 6,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
    ],
  },
  {
    title: 'Credit Debit Details',
    href: '#',
    id: 2,
    suffixColor: 'bg-info text-dark-white',
    icon: <i className="bi bi-bag-check"></i>,
    collapisble: true,
    children: [
      {
        title:'Credit Debit Details',
        href: '/ManageCreditDetails/CreditDebitDetails',
        id: 6,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      // {
      //   title: 'Plan',
      //   href: '/PlanMaster/AllPlans',
      //   id: 10,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Commission Plan',
      //   href: '/CommissionMaster/AllCommissionPlans',
      //   id: 14,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
    ],
  },
  {
    title: 'Manage Bank',
    href: '#',
    id: 2,
    suffixColor: 'bg-info text-dark-white',
    icon: <i className="bi bi-bag-check"></i>,
    collapisble: true,
    children: [
      {
        title:'Manage Self Bank',
        href: '/Admin/AdminSelfBank',
        id: 6,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'Manage Main Bank',
        href: '/Admin/MainBankList',
        id: 6,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'Bank Data Manager',
        href: '/Admin/BankList',
        id: 6,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
    ],
  },
  {
    title: 'Management',
    href: '#',
    id: 8,
    suffixColor: 'bg-info text-dark-white',
    icon: <i className="bi bi-eye"></i>,
    collapisble: true,
    children: [
      // {
      //   title:'Business Rights',
      //   href: '/Admin/BusinessRights',
      //   id: 44,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      {
        title: 'Systems Setting',
        href: '/Admin/SystemSetting',
        id: 10,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title:'Service Control',
        href: '/Admin/ServicesControl',
        id: 76,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title:'Login History',
        href: '/Admin/LoginHistory',
        id: 80,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },

      {
        title:'Complaint Manager',
        href: '/Admin/ComplaintManager',
        id: 6,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'Update Customer Limit',
        href: '/Admin/CustomerLimitInc',
        id: 86,
        suffixColor: '#ef7586',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'Update Customer Name',
        href: '/Admin/CustomerNameUpdate',
        id: 86,
        suffixColor: '#ef7586',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'Change Tpin',
        href: '/Admin/ChangeTpin',
        id: 86,
        suffixColor: '#ef7586',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title:'Notification',
        href: '/Admin/Notification',
        id: 93,
        suffixColor: '#ef7586',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
    ],
  },
  {
    title:'Reports',
    href: '#',
    id: 2,
    suffixColor: 'bg-info text-dark-white',
    icon: <i className="bi bi-bag-check"></i>,
    collapisble: true,
    children: [
      {
        title: 'User Ledger Reports',
        href: '/Admin/AdminLedgeReports',
        id: 6,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'ADMIN Ledger Reports',
        href: '/Admin/AdminSelfLedger',
        id: 6,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      // {
      //   title:'Payout Transaction Reports',
      //   href: '/Admin/PayOutTransactionReports',
      //   id: 7,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      {
        title:'Transaction Reports',
        href: '/Reports/AdminTransactionReport',
        id: 7,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'Bill Payment Reports',
        href: '/Reports/AdminBillPaymentReports',
        id: 9,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      // {
      //   title:'AEPS Transaction Reports',
      //   href: '/Reports/AEPSTransactionReportsAdmin',
      //   id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      {
        title: 'Top Up Report',
        href: '/Reports/AdminTopUpReport',
        // id: 6,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'Change Status By Txn Id',
        href: '/Admin/AdminChangeStatus',
        id: 10.10,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: 'API Request Response Status',
        href: '/Admin/APIRequestResponseAdmin',
        id: 14,
        suffixColor: '#4cb5bb',
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
    ],
  },
];

export default HorizontalSidebarData;
